<template>
  <v-container style="padding: 0">
    <div>
      <div>
        <v-skeleton-loader
          id="skeleton-store-img"
          v-bind="attrs"
          type="image"
          class="rounded-0 store-img"
          style="width: 100%"
        ></v-skeleton-loader>
      </div>
      <div>
        <v-skeleton-loader
          v-bind="attrs2"
          type="list-item-two-line"
          style="width: 100%; border: 1px solid #e0e0e0"
        ></v-skeleton-loader>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    attrs: {
      boilerplate: false,
      elevation: 0,
    },
    attrs2: {
      boilerplate: false,
      elevation: 0,
    },
  }),
};
</script>

<style>
.v-skeleton-loader__paragraph {
  padding: 0 16px 16px 16px !important;
}

.v-skeleton-loader {
  border-radius: 0 !important;
}

@media only screen and (min-width: 600px) and (max-width: 960px) {
  .store-img {
    height: 150px;
  }

  #skeleton-store-img .v-skeleton-loader__image {
    height: 150px;
  }
}

@media only screen and (max-width: 599px) {
  .store-img {
    height: 100px;
  }

  #skeleton-store-img .v-skeleton-loader__image {
    height: 100px;
  }
}

@media only screen and (min-width: 961px) {
  .store-img {
    height: 220px;
  }

  #skeleton-store-img .v-skeleton-loader__image {
    height: 220px;
  }
}
</style>
