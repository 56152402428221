<template>
  <div class="p-0">
    <SkeletonStore v-if="loading" />

    <!-- <router-link
      v-else
      :to="'/store/' + store.id"
      style="text-decoration: none; color: black"
    > -->
    <div v-else style="text-decoration: none; color: black">
      <v-card
        class="p-0 store-card"
        max-width="300"
        elevation="0"
        style="border: 2px solid #dfdede; padding: 10px; overflow: hidden"
      >
        <div style="position: relative">
          <router-link
            :to="'/store/' + store.id"
            style="text-decoration: none; color: black"
          >
            <v-img
              @click="openDrawer"
              class="store-img rounded"
              width="287"
              :src="store.image_url || store.store_img"
              :lazy-src="store.image_url || store.store_img"
              style="border: 1px solid #dfdede"
            ></v-img>
          </router-link>
          <v-icon
            @click="addFavorite(store)"
            dark
            :color="isFavorite()"
            style="position: absolute; bottom: 0; left: 0; padding: 5px"
          >
            mdi-heart
          </v-icon>
        </div>

        <router-link
          :to="'/store/' + store.id"
          style="text-decoration: none; color: black"
        >
          <!-- <v-card-text class="pb-0"> -->
          <div class="store-text-header">
            {{ limitString(store.store_name, 15, true) }}
          </div>
          <!-- </v-card-text> -->
        </router-link>
      </v-card>
    </div>
    <!-- </router-link> -->
  </div>
</template>

<script>
import SkeletonStore from "../components/skeleton/SkeletonStore.vue";
export default {
  name: "StoreList",
  data() {
    return {
      notFound: require("../assets/image-not-found.png"),
    };
  },
  methods: {
    openDrawer() {
      this.$emit("openDrawer", false);
    },
    limitString: function (text, count, insertDots) {
      return (
        text.slice(0, count) + (text.length > count && insertDots ? "..." : "")
      );
    },
    addFavorite: function (store) {
      let cookies = $cookies.get("isLogin");
      if (cookies == 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("message.title-err-login-first"),
        });
      } else {
        this.$gtag.event("store-favorite-click", {
          event_category: "Favorite Store",
          event_label: store.store_name,
          value: store.store_name,
        });
        let payload = store.id;
        this.$store.dispatch("shop_module/addStoreFavorite", payload);
      }
    },
    isFavorite() {
      if (this.store.has_favourites === 1) {
        return "#ff0090";
      } else {
        return "grey";
      }
    },
  },
  props: {
    store: Object,
    loading: Boolean,
  },
  components: {
    SkeletonStore,
  },
};
</script>

<style>
.store-text-header {
  text-align: center;
  font-size: 0.7em;
  font-weight: 500;
  margin-top: 12px;
  overflow: hidden;
}

@media only screen and (min-width: 600px) and (max-width: 960px) {
  .store-card {
    height: 200px;
  }

  .store-img {
    height: 150px;
  }
}

@media only screen and (max-width: 599px) {
  .store-card {
    height: 150px;
  }

  .store-img {
    height: 100px;
  }
}

@media only screen and (min-width: 961px) {
  .store-card {
    height: 280px;
  }

  .store-img {
    height: 220px;
  }

  .store-text-header {
    font-size: 1em;
    font-weight: 500;
    margin-top: 13px;
  }
}
</style>
