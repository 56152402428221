<template>
  <v-container>
    <v-row
      style="padding-right: 0px; margin-top: 0.3em"
      justify="space-between"
    >
      <v-col style="padding: 0 5px 0 5px; font-weight: bold">
        <div>
          <p class="ps-3 text_title">{{ title_name }}</p>
          <div v-if="sub_name !== 'undefined'">
            <p class="ps-3 text_title" style="font-size: 0.6em">
              {{ sub_name }}
            </p>
          </div>
          <p
            v-if="has_show === true"
            class="ps-3"
            style="font-size: 0.8em; text-align: right"
            @click="$router.push(link)"
          >
            {{ viewAll }}
            <v-icon style="color: black; width: 0.8em">{{ svgPath }}</v-icon>
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiChevronRightCircleOutline as chevron_right } from "@mdi/js";

export default {
  name: "Section",
  data() {
    return {
      svgPath: chevron_right,
    };
  },
  computed: {
    viewAll() {
      var str = this.$t("message.view-all");
      var stringArray = str.split(/(\s+)/);

      return stringArray[0];
    },
  },
  components: {},
  props: {
    title_name: String,
    sub_name: String,
    has_show: Boolean,
    link: String,
  },
  created() {},
};
</script>
