<template>
  <div>
    <Sidebar />
    <div class="ps-2 mt-7 pt-7">
      <UserName />
    </div>

    <div style="margin-bottom: 5em">
      <HeaderNavigasi />

      <!-- <v-container v-if="isLoading" class="mt-7">
        <div v-for="i in 3" :key="i">
          <v-row  justify="space-between">
            <v-col class="pb-1" cols="4" lg="2" md="2" sm="2">
              <v-skeleton-loader
                v-bind="attrs"
                type="image"
              ></v-skeleton-loader>
            </v-col>
            <v-col class="font-weight-medium pb-0">
              <v-skeleton-loader
                v-bind="attrs"
                type="image"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row style="overflow-x:auto;flex-wrap:nowrap" class="row-scroll">
            <v-col
              v-for="i in 10"
              cols="6"
              md="2"
              lg="2"
              sm="2"
              :key="i"
              class="parent-style-product pe-0"
            >
              <Skeleton />
            </v-col>
          </v-row>
        </div>
      </v-container>
      <div v-else> 
        <div 
        
        class="parent-class text-center d-flex justify-center"
        >
          <div style="margin-top: 10%" v-if="storeList.length == 0">
            <p class="font-weight-bold mt-4 grey--text">Tidak ada list shop</p>
          </div>
        </div>
      </v-container> -->
      <div v-if="isLoading">
        <v-container>
          <v-row>
            <v-col
              v-for="i in 10"
              :key="i"
              cols="6"
              lg="2"
              md="2"
              sm="2"
              active="1"
              style="padding: 5px 4px 0px 4px"
            >
              <Skeleton />
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div>
        <v-container>
          <v-row style="padding: 0px">
            <v-col
              v-for="(list, id) in allStore"
              :key="id"
              cols="6"
              lg="2"
              md="2"
              sm="2"
              active="1"
              style="padding: 5px 4px 10px 4px"
            >
              <StoreList :store="list" />
            </v-col>
          </v-row>

          <div class="text-start">
            <v-pagination
              color="#ff0090"
              v-model="page"
              :length="lastPageStore"
              :total-visible="5"
              class="mt-6"
              @input="nextStore"
            ></v-pagination>
          </div>
          <!-- <div v-observe-visibility="handleScroll"></div> -->
        </v-container>
      </div>
      <div>
        <CategoryFooter />
      </div>
    </div>
    <Cashier />
  </div>
</template>

<script>
import Sidebar from "../components/Sidebar.vue";
import CategoryFooter from "../components/CategoryFooter.vue";
import StoreSlide from "../components/StoreSlide.vue";
import Back from "../components/Back.vue";
import User from "../components/User.vue";
import StoreList from "../components/StoreList.vue";
import Cashier from "../components/Cashier.vue";
import HeaderNavigasi from "../components/HeaderNavigasi.vue";
import Skeleton from "../components/skeleton/Skeleton.vue";
import {
  mdiArrowLeft,
  mdiChevronRight,
  mdiMagnify,
  mdiCart,
  mdiHeartOutline,
  mdiStore,
} from "@mdi/js";
import { mapState } from "vuex";
export default {
  name: "Store",
  components: {
    Sidebar,
    BackComponent: Back,
    StoreList,
    StoreSlide,
    Skeleton,
    UserName: User,
    CategoryFooter,
    HeaderNavigasi,
    Cashier,
  },
  data() {
    return {
      iconArrowLeft: mdiArrowLeft,
      iconViewAll: mdiChevronRight,
      isLoading: false,
      page: null,
      attrs: {
        height: 30,
        class: "mb-1",
        boilerplate: true,
        elevation: 0,
      },
    };
  },
  beforeMount() {
    window.scrollTo(0, 0);
  },
  mounted() {
    // this.getNextUser()
  },
  methods: {
    async getAllStore() {
      this.isLoading = true;
      let pageInit = parseInt(
        this.$store.state.shop_module.paginationStore.page
      );
      this.page = parseInt(this.$store.state.shop_module.paginationStore.page);
      let data = await this.$store.dispatch("shop_module/fetchAllStore", {
        page: pageInit,
      });

      this.isLoading = false;
      return data;
    },
    // async getStoreListByCategory() {
    //   this.isLoading = true;
    //   let data = await this.$store.dispatch(
    //     "shop_module/getStoreListByCategory"
    //   );
    //   this.isLoading = false;
    //   return data;
    // },
    async nextStore() {
      this.isLoading = true;
      window.scrollTo(0, 0);

      let data = await this.$store.dispatch("shop_module/fetchAllStore", {
        page: this.page,
      });
      this.isLoading = false;
      return data;
    },
  },
  computed: {
    lastPageStore() {
      return this.$store.state.shop_module.paginationStore.lastPage;
    },
    // storeByCtgry() {
    //   return this.$store.state.shop_module.storeByCategory;
    // },
    allStore() {
      return this.$store.state.shop_module.storeAll;
    },
  },
  created() {
    // this.getStoreListByCategory();
    this.getAllStore();
  },
};
</script>

<style>
.v-main__wrap {
  background-color: #ffffff;
  margin-top: 1px;
}
.header-title {
  font-family: roboto;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-top: -15px;
  margin-bottom: 10px;
  height: 25px;
}
.parent-style-product {
  padding: 4px !important;
}
</style>
